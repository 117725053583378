import { render, staticRenderFns } from "./CetakBuktiBuku.vue?vue&type=template&id=6cba4811&scoped=true"
import script from "./CetakBuktiBuku.vue?vue&type=script&lang=js"
export * from "./CetakBuktiBuku.vue?vue&type=script&lang=js"
import style0 from "./CetakBuktiBuku.vue?vue&type=style&index=0&id=6cba4811&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cba4811",
  null
  
)

export default component.exports